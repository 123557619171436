import React, { FunctionComponent } from 'react';
import { HomeExtensionRootState, ToggleModal, MEDIA_L } from '@hec/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../routing';
import {
  IsCladdingProduct,
  IsFrameProduct,
  ModalContentKey,
  Product,
  TranslationKeys
} from '@hec/models';
import styles from './summary.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { BackButton, Button, CostsSummary, Text, useWindowDimensions, } from "@hec/components/v1";
import {ExtraInformationForKeyRenderer} from "../../../../dormer/src/components";

const hasMinimalProductSet = (products: Product[]) => {
  return products.find(IsFrameProduct) && products.find(IsCladdingProduct);
};

export const SummaryPageSideDrawerContent: FunctionComponent = () => {
  const { t } = useTranslation();
  const { homeConfigurationReducer } = useSelector((state: HomeExtensionRootState) => state);
  const { deviceType, width: windowWidth } = useWindowDimensions();
  const { selectedProducts } = homeConfigurationReducer;
  const { primaryColor, secondaryColor, extraInformation, pricePerCubicMeter, vatPercentage } = homeConfigurationReducer.clientConfiguration;
  const palette = {
    primaryColor,
    secondaryColor,
  };
  const dispatch = useDispatch();

  return (
    <>
      <div className={styles.summary}>
        <CostsSummary
          products={selectedProducts}
          extraInformation={extraInformation}
          hidePrice={pricePerCubicMeter === 0}
          vatPercentage={vatPercentage}
          additionalHeader={
            <div className={styles.column} id={"swag"}>
              <Text as={'span'} className={styles.summary__link} onClick={() => dispatch(ToggleModal(ModalContentKey.REQUEST_PDF))}>
                {t(TranslationKeys.pages.summaryPage.requestPdf, 'Download')}
              </Text>
              <Text as={'h3'} className={styles.summary__title}>
                {t(TranslationKeys.pages.summaryPage.name, 'Samenvatting')}
              </Text>
            </div>
          }
        />

      </div>
      <div className={styles.actions}>
        <div className={styles['action-button-container']}>
          <Link to={RoutePaths.ExtrasPath}>
            <BackButton
              palette={palette}
              type={
                deviceType === 'mobile' ||
                deviceType === 'tablet' ||
                windowWidth <= MEDIA_L ? 'minimal' : 'normal'
              }
            />
          </Link>
          <Button
            type={'cta'}
            size={'large'}
            palette={palette}
            disabled={!hasMinimalProductSet(selectedProducts)}
            onClick={() => {
              dispatch(ToggleModal(ModalContentKey.REQUEST_QUOTATION)) // HEC-337
            }}
          >
            {t(TranslationKeys.pages.summaryPage.cta, 'Offerte aanvragen')}
          </Button>
        </div>
      </div>
    </>
  );
};
