import React, {
  FormEvent,
  FunctionComponent,
  RefObject,
  useCallback,
  useEffect, useMemo,
  useRef
} from 'react';
import {
  Button,
  FormField,
  Input,
  useWindowDimensions,
  BackButton,
  CostAggregate,
  Conditional,
  Text,
} from "@hec/components/v1";
import styles from './measurements.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../routing';
import { TranslationKeys } from '@hec/models';
import { useDispatch, useSelector } from 'react-redux';
import { HomeExtensionRootState, setMeasurements, MEDIA_L } from '@hec/core';
import debounce from 'lodash/debounce';
import classnames from 'classnames';
// import {frameTemplateSelectedAction, getSelectedConfigurationTemplateSelector} from "@hec/dal";
// import {ConfigurationTemplateDto, FaceConfigurationSectionFragmentCombinationDto} from "@hec/api-dtos";
// import _ from "lodash";

export interface MeasurementsProps {
}

const isWithinThreshold = (min: number, max: number, value: number): boolean => {
  return value >= min && value <= max;
};

const getErrorMessage = (
  min: number,
  max: number,
  value: number,
  property: string,
) :string[] => {
  if (value < min) {
    return [`error.${property}.belowThreshold`, Math.round(min * 100).toString()];
  }

  if (value > max) {
    return [`error.${property}.aboveThreshold`, Math.round(max * 100).toString()];
  }

  return [];
}

const hasAnyErrors = (errors: Partial<Record<string, string[]>>) => {
  return Object
    .values(errors)
    .flat()
    .length > 0;
};

const parseInputValue = (ref: RefObject<HTMLInputElement>): number => {
  if (ref.current) {
    return ref.current.value ? parseInt(ref.current.value) / 100 : 0;
  }
  return 0;
}
export const Measurements: FunctionComponent<MeasurementsProps> = ({}) => {
  const { clientConfiguration, measurements, price } = useSelector((state: HomeExtensionRootState) => state.homeConfigurationReducer);
  const {
    primaryColor,
    secondaryColor,
    maxWidth,
    maxDepth,
    minWidth,
    minDepth,
    pricePerCubicMeter,
  } = clientConfiguration;
  const palette = {
    primaryColor,
    secondaryColor,
  };

  const {
    depth,
    width,
    height
  } = measurements;
  const [errors, setErrors] = useState<{ width: Array<string>, depth: Array<string> }>();
  const { orientation, deviceType, width: windowWidth } = useWindowDimensions();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialWidth = useMemo(() => width * 100, [width]);
  const initialDepth = useMemo(() => depth * 100, [depth]);

  const depthInputRef = useRef<HTMLInputElement>(null);
  const widthInputRef = useRef<HTMLInputElement>(null);

  useEffect(
    () => {
      return () => {
        handleFormChanges.cancel();
      }
    },
    [],
  );

  //
  // // This auto updates the preview to the biggest configuration supported.
  // const selectedConfigurationTemplate: ConfigurationTemplateDto | null = useSelector(getSelectedConfigurationTemplateSelector);
  // let selectableCombinations: FaceConfigurationSectionFragmentCombinationDto[] =
  //   _.clone((selectedConfigurationTemplate?.templateFaceConfiguration?.sectionFragmentCombinations ?? []));
  //
  // useEffect(() => {
  //
  //
  //
  //   selectableCombinations = selectableCombinations.sort((a,b) => (b?.maxWidth  ?? 0) - (a?.maxWidth ?? 0));
  //
  //   const suitableForConfigurationWidth = selectableCombinations
  //     .filter(x => x.maxWidth >= (width ?? 0) && x.minWidth <= (width ?? Infinity))
  //     .sort((a, b) => b.maxWidth - a.maxWidth);
  //
  //   dispatch(frameTemplateSelectedAction(
  //     {selectedFaceConfigurationSectionFragmentCombinationDto: suitableForConfigurationWidth[0]})
  //   );
  // }, [measurements.width, dispatch]);


  const handleFormChanges = useRef(debounce(
    (depth: number, width: number) => {
      dispatch(
        setMeasurements({
          height,
          depth,
          width,
        }),
      );
    },
    1000,
  )).current;

  const handleInputChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      if (depthInputRef.current && widthInputRef.current) {
        const depthInputValue = parseInputValue(depthInputRef);
        const widthInputValue = parseInputValue(widthInputRef);
        const inputName = (e.target as HTMLInputElement).name;
        const isWidthInputChanged = inputName === 'width';

        const withinThreshold = isWidthInputChanged ?
          isWithinThreshold(minWidth, maxWidth, widthInputValue) :
          isWithinThreshold(minDepth, maxDepth, depthInputValue);

        const errorMessage = isWidthInputChanged ?
          getErrorMessage(minWidth, maxWidth, widthInputValue, 'width'):
          getErrorMessage(minDepth, maxDepth, depthInputValue, 'depth');

        setErrors((state) => {
          let depthErrors = state ? state.depth : [];
          let widthErrors = state ? state.width : [];

          if (isWidthInputChanged) {
            widthErrors = withinThreshold ? [] : [t(errorMessage[0], { value: errorMessage[1] })]
          } else {
            depthErrors = withinThreshold ? [] : [t(errorMessage[0], { value: errorMessage[1] })]
          }

          return {
            depth: [...depthErrors],
            width: [...widthErrors],
          };
        });

        if (errorMessage.length === 0) {
          handleFormChanges(depthInputValue, widthInputValue);
        }
      }
    },
    [depthInputRef, widthInputRef, minWidth, maxWidth, minDepth, maxDepth, setErrors],
  );

  return (
    <>
      <div className={styles.title}>
        <Text as={'h1'}>
          {t(TranslationKeys.pages.measurementsPage.sideDrawerTitle, 'Afmetingen')}
        </Text>
        <Text as={'h1'}>
          {Math.round(depth * width)} m²
        </Text>
      </div>
      <div className={styles.form}>
        <form
          action=""
          onSubmit={(e) => e.preventDefault()}
        >
          <FormField
            label={t(TranslationKeys.generic.depth, 'Diepte (cm)')}
            errors={errors && errors.depth}
          >
            <Input
              type="number"
              name="depth"
              placeholder="200"
              defaultValue={initialDepth}
              ref={depthInputRef}
              min={200}
              className={errors && errors['depth'] ? styles.error : ''}
              onChange={handleInputChange}
            />
          </FormField>
          <FormField
            label={t(TranslationKeys.generic.width, 'Breedte (cm)')}
            errors={errors && errors.width}
          >
            <Input
              type="number"
              name="width"
              min={350}
              placeholder="350"
              ref={widthInputRef}
              defaultValue={initialWidth}
              className={errors && errors['width'] ? styles['form-error'] : ''}
              onChange={handleInputChange}
            />
          </FormField>
        </form>
      </div>
      <div className={styles.actions}>
        <Conditional condition={(deviceType === 'mobile' || deviceType === 'tablet') && orientation === 'portrait'}>
          <div className={styles.costs}>
            <CostAggregate
              palette={{
                primaryColor: primaryColor ?? 'black',
                secondaryColor: secondaryColor ?? 'white'
              }}
              hidePrice={pricePerCubicMeter === 0}
              totalPrice={price}
            />
          </div>
        </Conditional>
        <div className={styles['action-button-container']}>
          <Link
            to={RoutePaths.CladdingPath}
          >
            <BackButton
              palette={palette}
              type={
              deviceType === 'mobile' ||
              deviceType === 'tablet' ||
              windowWidth <= MEDIA_L ? 'minimal' : 'normal'} />
          </Link>
          <Link
            to={RoutePaths.FramesPath}
            className={classnames(styles.link, { [`${styles.disabled }`]: errors && hasAnyErrors(errors) })}
          >
            <Button
              type={'primary'}
              size={'large'}
              palette={palette}
              disabled={errors && hasAnyErrors(errors)}
              as={<div />}
            >
              {t(TranslationKeys.pages.measurementsPage.nextStep, 'Volgende stap: kozijnen')}
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};
