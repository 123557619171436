import { Route, TranslationKeys } from '@hec/models';
import { Cladding, Measurements, Frames, SummaryPageSideDrawerContent } from '../pages';
import { RoutePaths } from './RoutePaths';
import { Extras } from '../pages/Extras';

export const Routes: Route[] = [
  {
    name: 'routes.cladding',
    path: RoutePaths.CladdingPath,
    sideDrawerContent: <Cladding />,
    translationKey: TranslationKeys.pages.claddingPage.name,
    displaySettings: {
      displayInNavigation: true,
      displayTitleInDrawer: true
    }
  },
  {
    name: 'routes.measurements',
    path: RoutePaths.MeasurementsPath,
    sideDrawerContent: <Measurements />,
    translationKey: TranslationKeys.pages.measurementsPage.name,
    displaySettings: {
      displayInNavigation: true,
      displayTitleInDrawer: false
    }
  },
  {
    name: 'routes.frames',
    path: RoutePaths.FramesPath,
    sideDrawerContent: <Frames />,
    translationKey: TranslationKeys.pages.framesPage.name,
    displaySettings: {
      displayInNavigation: true,
      displayTitleInDrawer: true
    }
  },
  {
    name: 'routes.extras',
    path: RoutePaths.ExtrasPath,
    sideDrawerContent: <Extras />,
    translationKey: TranslationKeys.pages.extrasPage.name,
    displaySettings: {
      displayInNavigation: true,
      displayTitleInDrawer: true
    }
  },
  {
    name: 'routes.summary',
    path: RoutePaths.SummaryPath,
    sideDrawerContent: <SummaryPageSideDrawerContent />,
    translationKey: TranslationKeys.pages.summaryPage.sideDrawerTitle,
    displaySettings: {
      displayInNavigation: true,
      displayTitleInDrawer: true,
      titleNoMarginBottom: true,
      centerTitle: true,
      navigationTitleTranslationKey: TranslationKeys.pages.summaryPage.name
    }
  }
];
