import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  CladdingSelectionItem,
  Button,
  Conditional,
  CostAggregate,
  Dropdown,
  ProductSelectionList,
  setDropdownState,
  useWindowDimensions,
} from "@hec/components/v1";
import {
  CladdingProduct,
  FrameProduct,
  IsCladdingProduct,
  IsFrameProduct,
  Measurements,
  ModalContentKey,
  Product,
  TranslationKeys
} from '@hec/models';
import styles from './cladding.module.scss';
import { RoutePaths } from '../../routing';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { HomeExtensionRootState, selectProduct, ToggleModal } from '@hec/core';
import { splitByProperty } from '@hec/utils';

export interface CladdingProps {}

const claddingProductIterator = (pricePerCubicMeter: number, onModalToggle?: (item: CladdingProduct) => void) => (cladding: CladdingProduct) => {
  return {
    item: cladding,
    component: (
      <CladdingSelectionItem
        product={cladding}
        hidePrice={pricePerCubicMeter === 0}
        onModalToggle={onModalToggle}
      />
    ),
  };
};

export const Cladding: FunctionComponent<CladdingProps> = ({}) => {
  const { homeConfigurationReducer, applicationStateReducer } = useSelector((state: HomeExtensionRootState) => state);
  const { primaryColor, secondaryColor, pricePerCubicMeter, excludeFrameFromCladdingPriceCalculation } = homeConfigurationReducer.clientConfiguration;
  const { loading } = applicationStateReducer;
  const { orientation, deviceType } = useWindowDimensions();
  const { products, selectedProducts, price, measurements } = homeConfigurationReducer;
  const dispatch = useDispatch();
  const [dropdownStates, setDropdownStates] = useState<boolean[]>([]);
  const { t } = useTranslation();

  const selectedProduct = selectedProducts.find(IsCladdingProduct);

  const splitByProperties = splitByProperty<CladdingProduct>(products.filter(IsCladdingProduct), (p) => p.productionMaterialType);


  const openInformationModal = (product: Product) => dispatch(ToggleModal(ModalContentKey.PRODUCT_DESCRIPTION, product));

  useEffect(
    () => {
      if (Object.keys(splitByProperties).length > 0 && dropdownStates.length === 0) {
        const state = Object.keys(splitByProperties).map(() => false);
        if (state.length > 0) {
          state[0] = true;
          setDropdownStates(state);
        }
      }
    },
    [splitByProperties, dropdownStates, setDropdownStates]
  );

  const isLoadingProducts = loading
    .find((el) => el.actionType === 'GET_PRODUCTS')

  return (
    <>
      <div className={styles.sections}>
        <Conditional condition={!isLoadingProducts}>
          {
            Object
              .keys(splitByProperties)
              .map((key, index) => (
                <Dropdown
                  key={key}
                  title={t(`generic.materials.${key.toLowerCase()}`, key)}
                  isOpen={dropdownStates.length > 0 ? dropdownStates[index] : false}
                  control={(dropdownState) => {
                    setDropdownStates(setDropdownState(index, dropdownState));
                  }}
                >
                  <ProductSelectionList<CladdingProduct>
                    hideSubTotal={true}
                    activeBorderColor={primaryColor}
                    items={splitByProperties[key as keyof CladdingProduct] || []}
                    activeItem={selectedProduct as CladdingProduct | undefined}
                    onSelect={(p: CladdingProduct) => dispatch(selectProduct(p))}
                    iterator={claddingProductIterator(pricePerCubicMeter, openInformationModal)}
                  />

                </Dropdown>
              ))
          }
        </Conditional>
      </div>
      <div className={styles.actions}>
        <Conditional
            condition={(deviceType === 'mobile' || deviceType === 'tablet') && orientation === 'portrait'}
        >
          <div className={styles.costs}>
            <CostAggregate
              palette={{
                primaryColor: primaryColor ?? 'black',
                secondaryColor: secondaryColor ?? 'white'
              }}
              hidePrice={pricePerCubicMeter === 0}
              totalPrice={price}
            />
          </div>
        </Conditional>
        <div className={styles['action-button-container']}>
          <Link
            to={RoutePaths.MeasurementsPath}
          >
            <Button
              type={'primary'}
              size={'large'}
              palette={{
                primaryColor,
                secondaryColor
              }}
              as={<div />}
            >
              {t(TranslationKeys.pages.claddingPage.nextStep, 'Volgende stap: afmetingen')}
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};
