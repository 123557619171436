import { FatalErrorKey, TranslationKeys } from "@hec/models";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import styles from './fatal-error.module.scss';

export interface FatalErrorPageProps {
  fatalErrorKey: FatalErrorKey;
}

export const FatalErrorPage: FunctionComponent<FatalErrorPageProps> = ({ fatalErrorKey }) => {
  const { t } = useTranslation();

  setTimeout(() => { window.location.reload() }, 15000)
  return <div className={styles['fatal-error']}>
  <div className={styles.center}>
    <div className={styles.header}>
      {t(TranslationKeys.fatalError.somethingWentWrongHeader, 'Er is helaas iets misgegaan...')}
    </div>
    <div className={styles.subText}>
      {t(`${TranslationKeys.fatalError.fatalErrorKeyPrefix}.${fatalErrorKey}`, fatalErrorKey)}
      {t(`${TranslationKeys.fatalError.fatalErrorKeyHintPrefix}.${fatalErrorKey}`, '')}
    </div>
        <div className={styles.redirectText}>
      {t(TranslationKeys.fatalError.redirectSoonText)}
    </div>
  </div></div>;
}