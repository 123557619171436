import React, { FunctionComponent, useMemo, useState } from 'react';
import styles from './extras.module.scss';
import {
  BackButton,
  BaseSelectionItem,
  Button,
  Conditional,
  CostAggregate,
  Dropdown,
  RoofLightSelectionItem,
  ProductSelectionList,
  SizeSelector,
  Text,
  useWindowDimensions,
} from "@hec/components/v1";
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../routing';
import { MEDIA_L } from '@hec/core';
import classnames from 'classnames';
import {
  IsRoofOverhangProduct,
  IsRoofTrimProduct,
  RoofTrimProduct,
  RoofOverhangProduct,
  TranslationKeys,
  RoofLightProduct,
  IsRoofLightProduct,
  IsProductWithinWidthAndDepthThreshold,
  IsFrameHeaderProduct,
  FrameHeaderProduct,
  IsCladdingProduct,
  FrameHeaderStyle,
  DrainPipeProduct,
  IsDrainPipeProduct,
  Product,
  ModalContentKey,
  IsFaucetProduct,
  FaucetProduct,
  HasDrawableObject,
  IsCompatibleRoofLightProduct,
} from '@hec/models';
import { useDispatch, useSelector } from 'react-redux';
import { HomeExtensionRootState, selectProduct, ToggleModal } from '@hec/core';
import { useTranslation } from 'react-i18next';
import { splitByProperty } from '@hec/utils';

export interface ExtrasSideBarContentProps {}

const getRoofLightSelectionListItems = (
  items: RoofLightProduct[],
  width: number,
  depth: number,
  activeItem: RoofLightProduct | undefined
) => {
  const groupedOnName = splitByProperty(items, (p) => p.name);

  return Object.keys(groupedOnName)

    .map((name) => {
      let result = groupedOnName[name].filter(
        IsProductWithinWidthAndDepthThreshold(width, depth)
      );
      const activeItemInGroupedOnName = groupedOnName[name].find(
        (x) => x.id === activeItem?.id
      );

      // If other than default size is selected insert that option instead of the default size so it shows as selected
      if (
        activeItemInGroupedOnName &&
        result.indexOf(activeItemInGroupedOnName) === -1
      ) {
        const itemToReplace = result.find(
          (x) =>
            x.drawableObject?.color ===
            activeItemInGroupedOnName.drawableObject?.color
        );
        if (itemToReplace) {
          const indexOfItemToReplace = result.indexOf(itemToReplace);
          result[indexOfItemToReplace] = activeItemInGroupedOnName;
        }
      }

      return result;
    })
    .flat();
};

const getDrainPipeSelectionListItems = (items: DrainPipeProduct[]) => {
  const groupedOnName = splitByProperty(items, (p) => p.name);

  return Object.keys(groupedOnName)

    .map((name) => {
      return groupedOnName[name];
    })
    .flat();
};

const getFaucetSelectionListItems = (items: FaucetProduct[]) => {
  const groupedOnName = splitByProperty(items, (p) => p.name);

  return Object.keys(groupedOnName)

    .map((name) => {
      return groupedOnName[name];
    })
    .flat();
};

const roofTrimProductIterator =
  (hidePrice: boolean) => (roofTrimProduct: RoofTrimProduct) => {
    return {
      item: roofTrimProduct,
      component: (
        <BaseSelectionItem<RoofTrimProduct>
          hidePrice={hidePrice}
          product={roofTrimProduct}
        />
      ),
    };
  };
const roofOverhangProductIterator =
  (hidePrice: boolean) => (roofOverhangProduct: RoofOverhangProduct) => {
    return {
      item: roofOverhangProduct,
      component: (
        <BaseSelectionItem<RoofOverhangProduct>
          hidePrice={hidePrice}
          showImage={false}
          product={roofOverhangProduct}
        />
      ),
    };
  };
const roofLightProductIterator =
  (hidePrice: boolean, onModalToggle?: (item: RoofLightProduct) => void) =>
  (roofLightProduct: RoofLightProduct) => {
    return {
      item: roofLightProduct,
      component: (
        <RoofLightSelectionItem
          roofLightProduct={roofLightProduct}
          hidePrice={hidePrice}
          onModalToggle={onModalToggle}
        />
      ),
    };
  };

const frameHeaderProductIterator =
  (hidePrice: boolean) => (frameHeaderProduct: FrameHeaderProduct) => {
    return {
      item: frameHeaderProduct,
      component: (
        <BaseSelectionItem<FrameHeaderProduct>
          hidePrice={hidePrice}
          product={frameHeaderProduct}
          showImage={false}
        />
      ),
    };
  };

const drainPipeProductIterator =
  (hidePrice: boolean) => (drainPipeProduct: DrainPipeProduct) => {
    return {
      item: drainPipeProduct,
      component: (
        <BaseSelectionItem<DrainPipeProduct>
          hidePrice={hidePrice}
          product={drainPipeProduct}
          showImage={false}
          options={{ placementType: '' }}
        />
      ),
    };
  };

const faucetProductIterator =
  (hidePrice: boolean) => (drainPipeProduct: FaucetProduct) => {
    return {
      item: drainPipeProduct,
      component: (
        <BaseSelectionItem<FaucetProduct>
          hidePrice={hidePrice}
          product={drainPipeProduct}
          showImage={false}
          options={{ placementType: '' }}
        />
      ),
    };
  };

export const Extras: FunctionComponent<ExtrasSideBarContentProps> = ({}) => {
  const { t } = useTranslation();
  const { homeConfigurationReducer } = useSelector((state: HomeExtensionRootState) => state);
  const { primaryColor, secondaryColor, pricePerCubicMeter } =
    homeConfigurationReducer.clientConfiguration;
  const { measurements } = homeConfigurationReducer;
  const [dropdownState, setDropdownState] = useState<Record<string, boolean>>({
    roofTrim: true,
    roofOverhang: false,
    roofLightStyle: false,
    frameHeader: false,
    drainPipe: false,
    faucet: false,
  });
  const dispatch = useDispatch();
  const openInformationModal = (product: Product) =>
    dispatch(ToggleModal(ModalContentKey.PRODUCT_DESCRIPTION, product));

  const { price, products, selectedProducts } = homeConfigurationReducer;
  const palette = {
    primaryColor,
    secondaryColor,
  };

  const { orientation, deviceType, width } = useWindowDimensions();

  const selectedCladdingProduct = selectedProducts.find(IsCladdingProduct);

  const selectedRoofTrimProduct = selectedProducts.find(IsRoofTrimProduct);
  const selectedRoofOverhangProduct = selectedProducts.find(
    IsRoofOverhangProduct
  );
  const selectedRoofLightProduct = selectedProducts.find(IsRoofLightProduct);
  const selectedFrameHeaderProduct =
    selectedProducts.find(IsFrameHeaderProduct);
  const selectedDrainPipeProduct = selectedProducts.find(IsDrainPipeProduct);
  const selectedFaucetProduct = selectedProducts.find(IsFaucetProduct);

  const roofTrimProducts = products.filter(IsRoofTrimProduct);
  const roofOverhangProducts = products.filter(IsRoofOverhangProduct);
  const roofLightProducts = products.filter(IsRoofLightProduct);

  const drainPipeProducts = products.filter(IsDrainPipeProduct);
  const faucetProducts = products.filter(IsFaucetProduct);

  const frameHeaderProducts = products
    .filter(IsFrameHeaderProduct)
    .filter(
      (fhp) =>
        fhp.textureMaterial.specification.frameHeaderStyle !==
          FrameHeaderStyle.BRICKLINTEL ||
        fhp.textureMaterial.specification.forTextureMaterialId ===
          selectedCladdingProduct?.textureMaterial?.id
    );

  const splitByRoofLightProperties = useMemo(
    () =>
      splitByProperty<RoofLightProduct>(
        products.filter(IsRoofLightProduct).filter((p) => p.drawableObject),
        (p) => p.drawableObject.specification.roofLightPlacementType
      ),
    [products]
  );

  interface otherAvailableRoofLightSizes {
    smallerSizes: RoofLightProduct[];
    biggerSizes: RoofLightProduct[];
  }

  const roofLightSizesForCurrentSelectedRoofLightProduct: otherAvailableRoofLightSizes =
    useMemo(() => {
      if (!selectedRoofLightProduct?.name) {
        return {
          smallerSizes: [],
          biggerSizes: [],
        };
      }
      const sizes = products
        .filter((x) => x.name === selectedRoofLightProduct.name)
        .filter(IsRoofLightProduct)
        .filter(
          (x) =>
            x.drawableObject?.color ===
            selectedRoofLightProduct?.drawableObject?.color
        );
      const orderedSizes = sizes.sort((a, b) => {
        return a.minWidth + a.minDepth - (b.minWidth + b.minDepth);
      });

      const currentSelectedRoofLightInSortedSizes = orderedSizes.find(
        (x) => x.id === selectedRoofLightProduct.id
      );
      if (!currentSelectedRoofLightInSortedSizes) {
        return {
          smallerSizes: [],
          biggerSizes: [],
        };
      }

      const currentSelectedOrderedSizesIndex = orderedSizes.indexOf(
        currentSelectedRoofLightInSortedSizes
      );

      const result: otherAvailableRoofLightSizes = {
        smallerSizes: orderedSizes
          .slice(0, currentSelectedOrderedSizesIndex)
          .filter((x) => IsCompatibleRoofLightProduct(x, measurements)),
        biggerSizes: orderedSizes
          .slice(currentSelectedOrderedSizesIndex + 1, orderedSizes.length)
          .filter((x) => IsCompatibleRoofLightProduct(x, measurements)),
      };
      return result;
    }, [selectedRoofLightProduct, splitByRoofLightProperties]);

  const splitByDrainPipeProperties = useMemo(
    () =>
      splitByProperty<DrainPipeProduct>(
        products.filter(IsDrainPipeProduct),
        (p) => p.placementType
      ),
    [products]
  );

  const splitByFaucetProperties = useMemo(
    () =>
      splitByProperty<FaucetProduct>(
        products.filter(IsFaucetProduct).filter((p) => p.drawableObject),
        (p) => p.placementType
      ),
    [products]
  );

  return (
    <>
      <div className={styles.sections}>
        <Conditional condition={roofTrimProducts.length > 0}>
          <Dropdown
            title={t(TranslationKeys.pages.extrasPage.roofTrimDropdown)}
            isOpen={dropdownState.roofTrim}
            control={() => {
              setDropdownState((prevState) => {
                const oldState = { ...prevState };
                Object.keys(oldState).forEach((key) => (oldState[key] = false));
                return {
                  ...oldState,
                  roofTrim: !prevState.roofTrim,
                };
              });
            }}
          >
            <ProductSelectionList<RoofTrimProduct>
              items={roofTrimProducts}
              activeItem={selectedRoofTrimProduct}
              activeBorderColor={primaryColor}
              hideSubTotal={true}
              onSelect={(item) => {
                dispatch(selectProduct(item));
              }}
              iterator={roofTrimProductIterator(pricePerCubicMeter === 0)}
            />
          </Dropdown>
        </Conditional>
        <Conditional condition={roofOverhangProducts.length > 0}>
          <Dropdown
            title={t(TranslationKeys.pages.extrasPage.roofOverhangDropdown)}
            isOpen={dropdownState.roofOverhang}
            control={() => {
              setDropdownState((prevState) => {
                const oldState = { ...prevState };
                Object.keys(oldState).forEach((key) => (oldState[key] = false));
                return {
                  ...oldState,
                  roofOverhang: !prevState.roofOverhang,
                };
              });
            }}
          >
            <ProductSelectionList<RoofOverhangProduct>
              items={roofOverhangProducts}
              activeItem={selectedRoofOverhangProduct}
              hideSubTotal={true}
              activeBorderColor={primaryColor}
              onSelect={(item) => {
                dispatch(selectProduct(item));
              }}
              iterator={roofOverhangProductIterator(pricePerCubicMeter === 0)}
            />
          </Dropdown>
        </Conditional>
        <Conditional
          condition={
            roofLightProducts
              .filter(HasDrawableObject)
              .filter(
                IsProductWithinWidthAndDepthThreshold(
                  measurements.width,
                  measurements.depth
                )
              ).length > 0
          }
        >
          <Dropdown
            title={t(TranslationKeys.pages.extrasPage.roofLightStyleDropDown)}
            isOpen={dropdownState.roofLightStyle}
            control={() => {
              setDropdownState((prevState) => {
                const oldState = { ...prevState };
                Object.keys(oldState).forEach((key) => (oldState[key] = false));
                return {
                  ...oldState,
                  roofLightStyle: !prevState.roofLightStyle,
                };
              });
            }}
          >
            <ProductSelectionList<RoofLightProduct>
              items={roofLightProducts.filter((p) => !HasDrawableObject(p))}
              activeItem={selectedRoofLightProduct}
              activeBorderColor={primaryColor}
              hideSubTotal={true}
              onSelect={(item) => {
                dispatch(selectProduct(item));
              }}
              iterator={roofLightProductIterator(pricePerCubicMeter === 0)}
            />
            {Object.keys(splitByRoofLightProperties).map((key) => {
              return (
                <div key={key}>
                  <Text as={'h5'} className={styles['dropdown-subtitle']}>
                    {t(`generic.roofLightPlacementType.${key}`)}
                  </Text>
                  <ProductSelectionList<RoofLightProduct>
                    items={getRoofLightSelectionListItems(
                      splitByRoofLightProperties[key],
                      measurements.width,
                      measurements.depth,
                      selectedRoofLightProduct
                    ).sort((a, b) => a.name.localeCompare(b.name))}
                    activeItem={selectedRoofLightProduct}
                    activeBorderColor={primaryColor}
                    hideSubTotal={true}
                    onSelect={(item) => {
                      dispatch(selectProduct(item));
                    }}
                    iterator={roofLightProductIterator(
                      pricePerCubicMeter === 0,
                      openInformationModal
                    )}
                  />
                </div>
              );
            })}
            <Conditional
              condition={
                roofLightSizesForCurrentSelectedRoofLightProduct.biggerSizes
                  .length !== 0 ||
                roofLightSizesForCurrentSelectedRoofLightProduct.smallerSizes
                  .length !== 0
              }
            >
              <Text as={'h5'} className={styles['dropdown-subtitle']}>
                {t(`generic.size`, 'Formaat')}
              </Text>
              <SizeSelector
                onPlus={
                  roofLightSizesForCurrentSelectedRoofLightProduct.biggerSizes
                    .length > 0
                    ? () => {
                        const biggerSizes =
                          roofLightSizesForCurrentSelectedRoofLightProduct.biggerSizes;
                        if (biggerSizes) {
                          dispatch(selectProduct(biggerSizes[0]));
                        }
                      }
                    : undefined
                }
                onMinus={
                  roofLightSizesForCurrentSelectedRoofLightProduct.smallerSizes
                    .length > 0
                    ? () => {
                        const smallerSizes =
                          roofLightSizesForCurrentSelectedRoofLightProduct.smallerSizes;
                        if (smallerSizes) {
                          dispatch(
                            selectProduct(smallerSizes[smallerSizes.length - 1])
                          );
                        }
                      }
                    : undefined
                }
              />
            </Conditional>
          </Dropdown>
        </Conditional>
        <Conditional condition={frameHeaderProducts.length > 0}>
          <Dropdown
            title={t(TranslationKeys.pages.extrasPage.frameHeaderDropDown)}
            isOpen={dropdownState.frameHeader}
            control={() => {
              setDropdownState((prevState) => {
                const oldState = { ...prevState };
                Object.keys(oldState).forEach((key) => (oldState[key] = false));
                return {
                  ...oldState,
                  frameHeader: !prevState.frameHeader,
                };
              });
            }}
          >
            <ProductSelectionList<FrameHeaderProduct>
              items={frameHeaderProducts}
              activeItem={selectedFrameHeaderProduct}
              activeBorderColor={primaryColor}
              hideSubTotal={true}
              onSelect={(item) => {
                dispatch(selectProduct(item));
              }}
              iterator={frameHeaderProductIterator(pricePerCubicMeter === 0)}
            />
          </Dropdown>
        </Conditional>
        <Conditional condition={drainPipeProducts.length > 0}>
          <Dropdown
            title={t(TranslationKeys.pages.extrasPage.drainPipeDropdown)}
            isOpen={dropdownState.drainPipe}
            control={() => {
              setDropdownState((prevState) => {
                const oldState = { ...prevState };
                Object.keys(oldState).forEach((key) => (oldState[key] = false));
                return {
                  ...oldState,
                  drainPipe: !prevState.drainPipe,
                };
              });
            }}
          >
            {Object.keys(splitByDrainPipeProperties).map((key) => {
              return (
                <div key={key}>
                  <Text as={'h5'} className={styles['dropdown-subtitle']}>
                    {t(`generic.ProductPlacementType.${key}`)}
                  </Text>
                  <ProductSelectionList<DrainPipeProduct>
                    items={getDrainPipeSelectionListItems(
                      splitByDrainPipeProperties[key]
                    ).sort((a, b) => a.name.localeCompare(b.name))}
                    activeItem={selectedDrainPipeProduct}
                    activeBorderColor={primaryColor}
                    hideSubTotal={true}
                    onSelect={(item) => {
                      dispatch(selectProduct(item));
                    }}
                    iterator={drainPipeProductIterator(
                      pricePerCubicMeter === 0
                    )}
                  />
                </div>
              );
            })}
          </Dropdown>
        </Conditional>
        <Conditional condition={faucetProducts.length > 0}>
          <Dropdown
            title={t(TranslationKeys.pages.extrasPage.faucetDropdown)}
            isOpen={dropdownState.faucet}
            control={() => {
              setDropdownState((prevState) => {
                const oldState = { ...prevState };
                Object.keys(oldState).forEach((key) => (oldState[key] = false));
                return {
                  ...oldState,
                  faucet: !prevState.faucet,
                };
              });
            }}
          >
            <ProductSelectionList<FaucetProduct>
              items={faucetProducts.filter((p) => !p.drawableObject)}
              activeItem={selectedFaucetProduct}
              activeBorderColor={primaryColor}
              hideSubTotal={true}
              onSelect={(item) => {
                dispatch(selectProduct(item));
              }}
              iterator={faucetProductIterator(pricePerCubicMeter === 0)}
            />
            {Object.keys(splitByFaucetProperties).map((key) => {
              return (
                <div key={key}>
                  <Text as={'h5'} className={styles['dropdown-subtitle']}>
                    {t(`generic.ProductPlacementType.${key}`)}
                  </Text>
                  <ProductSelectionList<FaucetProduct>
                    items={getFaucetSelectionListItems(
                      splitByFaucetProperties[key]
                    ).sort((a, b) => a.name.localeCompare(b.name))}
                    activeItem={selectedFaucetProduct}
                    activeBorderColor={primaryColor}
                    hideSubTotal={true}
                    onSelect={(item) => {
                      dispatch(selectProduct(item));
                    }}
                    iterator={faucetProductIterator(pricePerCubicMeter === 0)}
                  />
                </div>
              );
            })}
          </Dropdown>
        </Conditional>
      </div>
      <div className={styles.actions}>
        <Conditional
          condition={
            (deviceType === 'mobile' || deviceType === 'tablet') &&
            orientation === 'portrait'
          }
        >
          <div className={styles.costs}>
            <CostAggregate
              palette={{
                primaryColor: primaryColor ?? 'black',
                secondaryColor: secondaryColor ?? 'white',
              }}
              hidePrice={pricePerCubicMeter === 0}
              totalPrice={price}
            />
          </div>
        </Conditional>
        <div className={styles['action-button-container']}>
          <Link to={RoutePaths.FramesPath}>
            <BackButton
              palette={palette}
              type={
                deviceType === 'mobile' ||
                deviceType === 'tablet' ||
                width <= MEDIA_L
                  ? 'minimal'
                  : 'normal'
              }
            />
          </Link>
          <Link to={RoutePaths.SummaryPath} className={classnames(styles.link)}>
            <Button
              type={'primary'}
              size={'large'}
              palette={palette}
              as={<div />}
            >
              {t(
                TranslationKeys.pages.extrasPage.nextStep,
                'Volgende stap: samenvatting'
              )}
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};
