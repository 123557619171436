import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styles from './frames.module.scss';
import {
  BackButton,
  Button,
  Conditional,
  CostAggregate,
  Dropdown,
  FrameSelectionItem,
  ProductSelectionList,
  setDropdownState,
  useWindowDimensions,
} from "@hec/components/v1";
import {
  IsFrameProduct,
  IsProductWithinWidthAndDepthThreshold,
  FrameProduct,
  ModalContentKey,
  TranslationKeys,
} from '@hec/models';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../routing';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PRODUCTS, HomeExtensionRootState, selectProduct, ToggleModal, MEDIA_L } from '@hec/core';
import { useTranslation } from 'react-i18next';
import { splitByProperty } from '@hec/utils';
import classnames from 'classnames';

export interface FramesProps {}

const getSelectionGroupedOnNames = (items: FrameProduct[]) => {
  return splitByProperty(items, (p) => p.name);
};

const getSelectionListItems = (items: FrameProduct[], width: number) => {
  const groupedOnName = getSelectionGroupedOnNames(items);

  return Object.keys(groupedOnName)
    .map((name) => {
      return groupedOnName[name].filter(
        IsProductWithinWidthAndDepthThreshold(width)
      );
    })
    .flat();
};
export const Frames: FunctionComponent<FramesProps> = ({}) => {
  const { homeConfigurationReducer, applicationStateReducer } = useSelector(
    (state: HomeExtensionRootState) => state
  );
  const { primaryColor, secondaryColor, pricePerCubicMeter } =
    homeConfigurationReducer.clientConfiguration;
  const palette = {
    primaryColor,
    secondaryColor,
  };
  const { loading } = applicationStateReducer;
  const { products, selectedProducts, price, measurements } =
    homeConfigurationReducer;
  const dispatch = useDispatch();
  const { orientation, deviceType, width } = useWindowDimensions();
  const [dropdownStates, setDropdownStates] = useState<boolean[]>([]);
  const { t } = useTranslation();
  const openInformationModal = (product: FrameProduct) =>
    dispatch(ToggleModal(ModalContentKey.PRODUCT_DESCRIPTION, product));

  const selectedFrame = selectedProducts.find(IsFrameProduct);
  const frameProductIterator = useMemo(
    () => (hidePrice: boolean) => (frame: FrameProduct) => ({
      item: frame,
      component: (
        <FrameSelectionItem
          hidePrice={hidePrice}
          frameProduct={frame}
          onModalToggle={openInformationModal}
        />
      ),
    }),
    [openInformationModal]
  );

  const splitByProperties = useMemo(
    () =>
      splitByProperty<FrameProduct>(
        products.filter(IsFrameProduct),
        (p) => p.productionMaterialType
      ),
    [products]
  );

  useEffect(() => {
    if (
      Object.keys(splitByProperties).length > 0 &&
      dropdownStates.length === 0
    ) {
      const state = Object.keys(splitByProperties).map(() => false);
      if (state.length > 0) {
        state[0] = true;
        setDropdownStates(state);
      }
    }
  }, [splitByProperties, dropdownStates, setDropdownStates]);

  const isLoadingProducts = loading.find(
    (el) => el.actionType === GET_PRODUCTS
  );

  return (
    <>
      <div className={styles.sections}>
        <Conditional condition={!isLoadingProducts}>
          {Object.keys(splitByProperties).map((key, index) => (
            <Dropdown
              key={key}
              title={t(`generic.materials.${key.toLowerCase()}`, key)}
              isOpen={dropdownStates.length > 0 ? dropdownStates[index] : false}
              control={(dropdownState) => {
                setDropdownStates(setDropdownState(index, dropdownState));
              }}
            >
              <ProductSelectionList<FrameProduct>
                hideSubTotal={true}
                activeBorderColor={palette.primaryColor}
                items={getSelectionListItems(
                  splitByProperties[key],
                  measurements.width
                )}
                activeItem={selectedFrame}
                onSelect={(p) => dispatch(selectProduct(p))}
                iterator={frameProductIterator(pricePerCubicMeter === 0)}
              />
            </Dropdown>
          ))}
        </Conditional>
      </div>
      <div className={styles.actions}>
        <Conditional
          condition={
            (deviceType === 'mobile' || deviceType === 'tablet') &&
            orientation === 'portrait'
          }
        >
          <div className={styles.costs}>
            <CostAggregate
              palette={{
                primaryColor: primaryColor ?? 'black',
                secondaryColor: secondaryColor ?? 'white',
              }}
              hidePrice={pricePerCubicMeter === 0}
              totalPrice={price}
            />
          </div>
        </Conditional>
        <div className={styles['action-button-container']}>
          <Link to={RoutePaths.MeasurementsPath}>
            <BackButton
              palette={palette}
              type={
                deviceType === 'mobile' ||
                deviceType === 'tablet' ||
                width <= MEDIA_L
                  ? 'minimal'
                  : 'normal'
              }
            />
          </Link>
          <Link
            to={RoutePaths.ExtrasPath}
            className={classnames(styles.link, {
              [`${styles.disabled}`]: typeof selectedFrame === 'undefined',
            })}
          >
            <Button
              type={'primary'}
              size={'large'}
              palette={palette}
              disabled={typeof selectedFrame === 'undefined'}
              as={<div />}
            >
              {t(
                TranslationKeys.pages.framesPage.nextStep,
                "Volgende stap: extra's"
              )}
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};
