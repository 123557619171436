import React, { FunctionComponent } from 'react';
import { FrameProduct, ModalContentKey } from '@hec/models';
import { useTranslation } from 'react-i18next';
import { HomeExtensionApplicationState, TranslationKeys } from '@hec/models';
import {
  FormRequestQuotation,
  FormRequestPdf,
  CostsSummary,
  FrameInformation,
  Help,
  Conditional,
} from "@hec/components/v1";
import { useSelector } from 'react-redux';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
// import {ExtraInformationForKeyRenderer} from "../../../dormer/src/components";

export interface ModalContentFactoryProps {
  modalKey: ModalContentKey;
}

export const ModalContentFactory: FunctionComponent<
  ModalContentFactoryProps
> = ({ modalKey }) => {
  const { trackPageView } = useMatomo();
  const { t } = useTranslation();
  const state = useSelector((state: HomeExtensionApplicationState) => state);
  const { clientConfiguration, selectedProducts } =
    state.homeConfigurationReducer;
  const { pricePerCubicMeter, vatPercentage } = clientConfiguration;
  const { settings } = state.applicationStateReducer;

  React.useEffect(() => {
    trackPageView({
      documentTitle: `modal.${modalKey}`,
      href: window.document.location.href,
    });
  }, [modalKey]);

  return (
    <>
      <Conditional condition={modalKey === ModalContentKey.COST_SUMMARY}>
        {/*<ExtraInformationForKeyRenderer*/}
        {/*  extraInformationKey={'CostsSummaryTop'}*/}
        {/*/>*/}
        <CostsSummary
          isInModal={true}
          hidePrice={pricePerCubicMeter === 0}
          columnTitle={t(TranslationKeys.modals.costSummaryModal.title)}
          products={selectedProducts}
          extraInformation={clientConfiguration.extraInformation}
          vatPercentage={vatPercentage}
        />
        {/*<ExtraInformationForKeyRenderer*/}
        {/*  extraInformationKey={'CostsSummaryBottom'}*/}
        {/*/>*/}
      </Conditional>
      <Conditional condition={modalKey === ModalContentKey.HELP}>
        <Help
          title={t(TranslationKeys.modals.helpModal.title)}
          clientConfiguration={clientConfiguration}
        />
      </Conditional>
      <Conditional condition={modalKey === ModalContentKey.PRODUCT_DESCRIPTION}>
        <FrameInformation frameProduct={settings.modalData as FrameProduct} />
      </Conditional>
      <Conditional condition={modalKey === ModalContentKey.REQUEST_QUOTATION}>
        <FormRequestQuotation />
      </Conditional>
      <Conditional condition={modalKey === ModalContentKey.REQUEST_PDF}>
        <FormRequestPdf />
      </Conditional>
    </>
  );
};
