import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import App from './app/App';
import {Provider} from 'react-redux';
import {ConfigureStore, InitializeApplication, raiseError, StateGenerator, UpdateAppConfiguration,} from '@hec/core';
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom';
import {
  AppConfiguration,
  ApplicationType,
  ErrorEventData,
  HEC_ERROR,
  HomeExtensionApplicationState,
  PublicConfiguratorApi,
} from '@hec/models';
import {Store} from 'redux';
import styles from './main.module.scss';
import {I18nextProvider} from 'react-i18next';
import i18NextInitialize from '@hec/i18n';
import {ReInitializeClassName} from "@hec/components/v1";
import {Loader} from '@react-three/drei';

import {createInstance, MatomoProvider} from '@jonkoops/matomo-tracker-react'

declare global {
  interface Window {
    HomeExtensionConfigurator: PublicConfiguratorApi;
  }
}

export class HomeExtensionConfigurator {
  private static instance: HomeExtensionConfigurator;
  private _store: Store;
  private readonly _element: HTMLElement;

  public static init(configuration: AppConfiguration) {
    const baseUrl = configuration?.baseUrl ?? process.env.NX_BASE_URL;
    const matomoUrl = configuration?.matomoUrl ?? process.env.NX_MATOMO_URL;

    const elementToInitialize =
      configuration.element || document.getElementById('root');
    if (
      !this.instance ||
      elementToInitialize?.className === ReInitializeClassName
    ) {
      this.instance = new HomeExtensionConfigurator(elementToInitialize);
    }
    if (this.instance.element) {
      this.instance.applyStylesToDocument();
      setTimeout(() => {
        this.instance.render(baseUrl!, matomoUrl ?? '');
        try {
          const state = StateGenerator();
          this.instance.listenForWindowEvents();
          if (document) {
            document.body.classList.add('pin-me');
            const htmlTag = document.querySelector('html');
            if (htmlTag) {
              htmlTag.classList.add('pin-me');
            }
          }

          this.instance.store.dispatch(
            InitializeApplication(
              {
                ...configuration,
                baseUrl: baseUrl,
                matomoUrl: matomoUrl
              },
                ApplicationType.HomeExtension,
              {homeConfiguration: state}
            )
          );
        } catch (e) {
          this.instance.store.dispatch(raiseError(e as any));
        }
      }, 500);
    }
  }

  public static updateConfiguration(configuration: AppConfiguration) {
    this.instance.store.dispatch(UpdateAppConfiguration(configuration));
  }

  public static close(maintainState: boolean = true, callback?: () => void) {
    if (this.instance.element) {
      const parent = this.instance.element.parentNode;
      if (parent) {
        parent.removeChild(this.instance.element);
        const newElement = document.createElement('div');
        newElement.id = 'root';
        newElement.className = ReInitializeClassName;
        parent.insertBefore(newElement, parent.childNodes[0]);
      }
    }
    if (document.body) {
      document.body.classList.remove('pin-me');
    }

    const htmlTag = document.querySelector('html');

    if (htmlTag) {
      htmlTag.classList.remove('pin-me');
    }

    if (!maintainState) {
      this.instance.store = ConfigureStore();
    }
    if (callback) {
      callback();
    }
  }

  private get store(): Store {
    return this._store;
  }

  private set store(value) {
    this._store = value;
  }

  private get element(): HTMLElement {
    return this._element;
  }

  private applyStylesToDocument(): void {
    this._element.classList.add(styles['configurator-init']);
  }

  private render(baseUrl: string, matomoUrl: string): void {
    const state = (this.store.getState() as HomeExtensionApplicationState);

    const language = state.applicationStateReducer.configuration.languageCode;

    const i18n = i18NextInitialize(baseUrl, language);
    const root = createRoot(this.element);

    const matomoTracker = createInstance({
      urlBase: matomoUrl,
      siteId: 1,
      configurations: {
        disableCookies: true,
        setSecureCookie: false,
        setRequestMethod: 'POST'
      }
    });

    root.render(
      <MatomoProvider value={matomoTracker}>
        <Suspense
          fallback={
            <Loader
              containerStyles={{background: 'white'}}
              barStyles={{background: 'black'}}
              dataStyles={{color: 'black'}}
            />
          }
        >
          <Provider store={this.store}>
            <I18nextProvider i18n={i18n}>
              <HashRouter>
                <App/>
                <Routes>
                  <Route path="/" element={<Navigate to="/cladding"/>}/>
                </Routes>
              </HashRouter>
            </I18nextProvider>
          </Provider>
        </Suspense>
      </MatomoProvider>,
    );
  }

  private listenForWindowEvents() {
    window.addEventListener(HEC_ERROR, (event) => {
      const eventData = (event as CustomEvent<ErrorEventData>).detail;
      this.store.dispatch(raiseError(new (Error as any)(eventData.message)));
    });
  }

  private constructor(element: HTMLElement | null) {
    if (!element) {
      throw new (Error as any)(
        'Cannot create configurator instance without an element'
      );
    }
    this._store = ConfigureStore();
    this._element = element;
  }
}

window.HomeExtensionConfigurator = HomeExtensionConfigurator;
