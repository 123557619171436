import styles from './App.module.scss';
import {Routes} from '../routing';
import {
  Drawer,
  Modal,
  Header,
  Navigation,
  NotificationContainer,
  ExtensionsRenderer,
  useWindowDimensions,
  DrawerToggle,
  Conditional,
  ANIMATION_LENGTH,
  CloseButton,
  CloseApp,
  Prompt,
  Text,
} from "@hec/components/v1";
import React, {Suspense, useEffect} from 'react';
import {
  cancelConfigurationPrompt,
  confirmConfigurationPrompt,
  INITIALIZE_APPLICATION,
  toggleFullscreen,
  ToggleModal,
  MEDIA_L
} from '@hec/core';
import {useDispatch, useSelector} from 'react-redux';
import {HomeExtensionApplicationState, ModalContentKey, TranslationKeys} from '@hec/models';
import classnames from 'classnames';
import {Loader} from '@react-three/drei';
import {useTranslation} from 'react-i18next';
import {ModalContentFactory} from '../modals/ModalContentFactory';
import {FatalErrorPage} from '../pages';

const MOBILE_BREAKPOINT = 414;

export function App() {
  const state = useSelector((state: HomeExtensionApplicationState) => state);
  const {notifications, loading} = state.applicationStateReducer;
  const {fullscreenMode, modalOpen, modalContentKey, fatalErrorKey} =
    state.applicationStateReducer.settings;
  const {
    price,
    clientConfiguration,
    selectedProducts,
    measurements,
    configurationPrompt,
  } = state.homeConfigurationReducer;
  const {pricePerCubicMeter} = clientConfiguration;
  const depthInMeters = measurements.depth;
  const widthInMeters = measurements.width;
  const {width, orientation, deviceType} = useWindowDimensions();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const isMobilePortrait =
    width <= MOBILE_BREAKPOINT &&
    orientation === 'portrait' &&
    deviceType === 'mobile';
  const isMobileLandscape =
    orientation === 'landscape' && deviceType === 'mobile';
  const isNonMobileLandscape =
    deviceType === 'desktop' ||
    (deviceType === 'tablet' && orientation === 'landscape');

  const isInitializing = loading.find((l) => l.actionType === INITIALIZE_APPLICATION);

  const getZoom = (): number => {
    if (isMobileLandscape) {
      return 12;
    }
    if (isMobilePortrait) {
      return 13;
    }
    return 8;
  };

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      dispatch(toggleFullscreen());
    }, ANIMATION_LENGTH);
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, []);




  return (
    <>
      <Conditional condition={!!fatalErrorKey}>
        <FatalErrorPage fatalErrorKey={fatalErrorKey!}/>
      </Conditional>
      <Conditional condition={
        !fatalErrorKey &&
        (typeof isInitializing !== 'undefined' ? !isInitializing.loading : true)
      }
      >
        <Suspense
          fallback={
            <Loader
              containerStyles={{background: 'white'}}
              barStyles={{background: 'black'}}
              dataStyles={{color: 'black'}}
            />
          }
        >
          <NotificationContainer notifications={notifications} translator={t}/>
          <div className={styles.app} style={{backgroundColor: 'white'}}>
            <Modal
              isOpen={modalOpen}
              onClose={() => dispatch(ToggleModal(ModalContentKey.NONE))}
            >
              <ModalContentFactory modalKey={modalContentKey}/>
            </Modal>
            <Prompt
              isOpen={configurationPrompt.active}
              conflictedProducts={configurationPrompt.conflictedProducts}
              onConfirm={() => {
                if (configurationPrompt.limboKey) {
                  dispatch(
                    confirmConfigurationPrompt(configurationPrompt.limboKey)
                  );
                }
              }}
              onCancel={() => {
                if (configurationPrompt.limboKey) {
                  dispatch(
                    cancelConfigurationPrompt(configurationPrompt.limboKey)
                  );
                }
              }}
            />
            <div className={classnames(styles.layout)}>
              <div
                id="content-inner"
                className={classnames(styles['layout-inner'], styles.loaded, {
                  [`${styles.fullscreen}`]: fullscreenMode,
                })}
              >
                <Header
                  totalPrice={price}
                  hidePrice={pricePerCubicMeter === 0}
                  clientConfiguration={clientConfiguration}
                  fullScreen={fullscreenMode}
                  showCostAggregate={isNonMobileLandscape}
                />
                <Conditional condition={selectedProducts.length > 0}>
                  <ExtensionsRenderer
                    zoom={fullscreenMode ? getZoom() * 0.5 : getZoom()}
                    products={selectedProducts}
                    depthInMeters={depthInMeters}
                    widthInMeters={widthInMeters}
                    className={classnames(styles.renderer, {
                      [`${styles['fullscreen']}`]: fullscreenMode,
                    })}
                  />
                </Conditional>
                <DrawerToggle
                  open={!fullscreenMode}
                  className={classnames(styles['drawer-toggle'], {
                    [`${styles.closed}`]: fullscreenMode,
                  })}
                  onClick={() => {
                    dispatch(toggleFullscreen());
                  }}
                />
                <CloseButton
                  onClose={() => {
                    const currentUrl = new URL(window.location.href);
                    let sourceUrl = currentUrl.searchParams.get('sourceUrl') ?? undefined;
                    if (sourceUrl) {
                      sourceUrl = decodeURIComponent(sourceUrl);
                    }

                    if (sourceUrl !== undefined) {
                      window.location.href = sourceUrl;
                    }

                    CloseApp();
                  }}
                  isBackgroundToggled={fullscreenMode}
                />
                <Conditional
                  condition={isNonMobileLandscape && width > MEDIA_L}
                >
                  <Navigation
                    routes={Routes}
                    palette={{
                      primaryColor: clientConfiguration.primaryColor,
                      secondaryColor: clientConfiguration.secondaryColor,
                    }}
                    hidden={fullscreenMode}
                    state={state}
                  />
                </Conditional>
                <Text
                  className={classnames(styles.help, {
                    [`${styles.hidden}`]: fullscreenMode,
                  })}
                  onClick={() => dispatch(ToggleModal(ModalContentKey.HELP))}
                >
                  {t(TranslationKeys.generic.help, 'Hulp nodig?')}
                </Text>
              </div>
              <Drawer routes={Routes} isOpen={!fullscreenMode}/>
            </div>
          </div>
        </Suspense>
      </Conditional>
    </>
  );
}

export default App;
